// import React, { FC, useState, createContext } from "react";
import moment from "moment";
import { IPatient } from "./interfaces";
import * as patientsApi from "services/patients";


export const getPatients = async (
  projectId: any,
  fields: any,
  include: any,
): Promise<any> => {
  try {
    const result = await patientsApi.getPatients({
      projectId,
      fields,
      include,
    });
    return {
      loading: false,
      patients: result.items.map((_: IPatient) => ({
        ..._,
        dob: moment(_.dob),
        fullName: `${_.firstName} ${_.lastName}`,
        devices: _.devices.sort((u1: any, u2: any) => (u1.id < u2.id ? 1 : -1)),
      })),
      jobs: result.jobs,
    };
  } catch (e) {
    return {
      loading: false,
      error: e?.response?.data?.error?.message || e.message,
    };
  }
};

export const importPatients = async (data: any): Promise<any> => {
    try {
      const result = await patientsApi.importPatients(data);
      return {
        loading: false,
        importPatientsResult: { successful: true, result } as any,
      };
    } catch (e) {
      return {
        loading: false,
        error: e?.response?.data?.error?.message || e.message,
        importPatientsResult: {
          successful: false,
          errors: e?.response?.data?.error || e,
        } as any,
      };
    }
};
  
export async function createPatient (data: any, callback: any): Promise<any> {
  try {
    await patientsApi.createPatient(data);
    callback && await callback();
    return { loading: false };

    // Notification({ type: 'success', message: 'Patient was successfully created' })
  } catch (e) {
    return {
      loading: false,
      error: e?.response?.data?.error?.message || e.message,
    };

    // Notification({
    //   type: 'error',
    //   message: 'Patient was not created',
    //   description: e?.response?.data?.error?.message || e.message,
    // })
  }
};

export const getPatient = async (id: any, callback: any): Promise<any> => {
  try {
    const patient = await patientsApi.getPatient(id);
    callback && (await callback());
    return ({
      patient: {
        ...patient.items[0],
        dob: moment(patient.items[0].dob),
      },
    });
  } catch (e) {
    return({
      loading: false,
      error: e?.response?.data?.error?.message || e.message,
    });

    // Notification({
    //   type: 'error',
    //   message: 'Cannot get patient details',
    //   description: e?.response?.data?.error?.message || e.message,
    // })
  }
};

export const getJob = async (
  projectId: any,
  jobId: any,
  callback: any
): Promise<any> => {
  try {
    const result = await patientsApi.getJobDetails({ projectId, jobId });
    callback && (await callback());
    return({
      job: result.items,
      loading: false,
    });
  } catch (e) {
    return({
      loading: false,
      error: e?.response?.data?.error?.message || e.message,
    });
  }
};

export const updatePatient = async (
  id: any,
  data: any,
  callback: any
): Promise<any> => {
  try {
    
    await patientsApi.updatePatient(id, data);
    if (callback) {
      await callback();
    }
    return({ loading: false });

    // Notification({ type: 'success', message: 'Patient was successfully updated' })

  } catch (e) {
    return({
      loading: false,
      error: e?.response?.data?.error?.message || e.message,
    });

    // Notification({
    //   type: 'error',
    //   message: 'Patient was not updated',
    //   description: e?.response?.data?.error?.message || e.message,
    // })
  }
};

export const deletePatient = async (
  id: any,
  params: any,
  callback: any
): Promise<any> => {
  try {
    await patientsApi.deletePatient(id, params);
    if (callback) {
      await callback();
    }
    return ({ loading: false });
    // Notification({ type: 'success', message: 'Patient was successfully deleted' })
  } catch (e) {
    return({
      loading: false,
      error: e?.response?.data?.error?.message || e.message,
    });

    // Notification({
    //   type: 'error',
    //   message: 'Patient was not deleted',
    //   description: e?.response?.data?.error?.message || e.message,
    // })
  }
};
